body {
    /* background-color: #fff157; */
    /* background-image: url("/natal_bg_pc.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed; */
    background: #f8f1df;
    /* background: linear-gradient(147deg, rgba(255,253,83,1) 0%, rgba(127,229,250,1) 100%); */
    
}

.bold {
    font-weight:  bold;
    display: inline;
    color: white;
    text-decoration:underline;

}

.logo-legend {
    margin-top: 15px;
    margin-bottom: 15px;
    color: black;
    font-style: italic;
}

.logo-legend img{
    height: 20px;
    width: auto;
}


@media only screen and (max-width: 600px) {
    body {
        /* background-image: url("/natal_bg_mobile.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed; */
        background: #f8f1df;
        /* background: linear-gradient(147deg, rgba(255,253,83,1) 0%, rgba(127,229,250,1) 100%); */
    }
}

.chapeu {
    width: 250px;
    margin-top: 35px;   
}

.home {
    text-align:center;
}

.link {
    color: white;
    text-decoration:underline;
}

.titulo {
    margin-bottom:50px;
    padding-bottom:4px;
    border-radius:15px;
    color:white;
    padding-top:5px;
    margin-top:10px;
    background-color:#74aa79;
    width:30%;
    margin-left:35%;
    position: relative;
    text-align: center;
    font-weight: bold;
}



.titulo h1 {
    /* margin-bottom:30px; */
     color: #3f6041 !important;
     font-weight: bold;
     text-align:center;
 }
 

.titulo img {
    position: absolute;
    right:-15%;
    top:-30px;
    width:40%;
    /* transform: rotate(30deg); */
    transform: rotate(30deg);

}




.opcoes-orcamento {
    background-color:#74aa79;
    float:left;
    min-height:50px;
    width:70%;
    margin-left:15%;
    color:white;
    border-radius:20px;
    margin-top:25px;
    margin-bottom:25px;
    padding-top:20px;
    padding-bottom:20px;
}

.opcoes-orcamento img{
    width:35px;
}

.ou-entao {
    float:left;
    text-align:center;
    width:100%;
}


@media only screen and (max-width: 600px) {
    .opcoes-orcamento h3 {
        font-size:19px;
        font-weight:bold;
    }

    .titulo {
        margin-bottom:35px;
        padding-bottom:4px;
        border-radius:15px;
        color:white;
        padding-top:5px;
        margin-top:35px;
        background-color:#74aa79;
        width:60%;
        margin-left:20%;
        position: relative;
    }
    
    .titulo img {
        position: absolute;
        right:-15%;
        top:-30px;
        width:40%;
        transform: rotate(30deg);
    }
}
