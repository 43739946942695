img {
    width:70%;
}

.item-modal {
    /* background-color:white; */
    background-color:rgba(255, 255, 255, 0.3);
}


.mais-info {
    margin-left:20px;
}

.add-to-cart {
cursor: pointer;
}

.thumbnail img {
    width:100%;
    height:200px !important;
}

p {
    color: #3f6041;
}

b {
    color: #3f6041;
}


.more-info-buttom {
    background-color: #3f6041;
}