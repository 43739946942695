

.lista-produtos h1 {
   margin-bottom:30px;
    color: #3f6041 !important;
    font-weight: bold;
    text-align:center;
}

.logo-legend {
    margin-top: 15px;
    margin-bottom: 15px;
    color: black;
}

.logo-legend img{
    height: 20  px;
    width: auto;
}

.lista-produtos {
     text-align:center;
 }

body {
    background-color: #3f6041;
}

* {
    color: #3f6041;
}

.filter {
    margin-bottom:15px;
}

.chapeu {
    width: 250px;
    margin-top: 35px;   
}


.titulo {
    margin-bottom:15px;
    padding-bottom:15px;
    border-radius:15px;
    color:white;
    padding-top:5px;
    margin-top:10px;
    background-color:#ff441a;
    width:30%;
    margin-left:35%;
    position: relative;
    text-align: center;
}

.titulo img {
    position: absolute;
    right:-15%;
    top:-30px;
    width:40%;
    transform: rotate(30deg);
}




@media only screen and (max-width: 600px) {

    .titulo {
        margin-bottom:15px;
        padding-bottom:15px;
        border-radius:15px;
        color:white;
        padding-top:5px;
        margin-top:35px;
        background-color:#ff441a;
        width:60%;
        margin-left:20%;
        position: relative;
    }
    
    .titulo img {
        position: absolute;
        right:-15%;
        top:-30px;
        width:40%;
        transform: rotate(30deg);
    }
}
