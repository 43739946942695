button {
    color:black;
}

h1 {
    text-align: center;
}

.logo {
    text-align: center;
}

.logo-legend {
    margin-top: 15px;
    margin-bottom: 15px;
    color: black;
    text-align: center;
}

.logo-legend img{
    height: 20  px;
    width: auto;
}

.pagar-pagseguro {
    background-color: orange;
    padding:15px;
    border-radius:7px;
    color:black;
    font-weight: bold;
    float:left;
    text-align:center;
    width:20%;
    margin-left:40%;
}

.pagar-pagseguro img {
    width:20px;
    margin-left:15px;
}

.continuar-comprando {
    background-color: blue;
    padding:15px;
    border-radius:7px;
    color:white;
    font-weight: bold;
    margin-top:25px;
    float:left;
    text-align:center;
    width:20%;
    margin-left:40%;
}

.numero-contato {
    text-align: center;
    float:left;
    width:100%;
    font-weight:bold;
    margin-top:20px;
}

.ou-entao {
    text-align: center;
    color: #74aa79;
    float:left;
    width:100%;
    font-weight:bold;
    margin-top:10px;
    margin-bottom:10px;
}




@media only screen and (max-width: 600px) {
    .pagar-pagseguro, .continuar-comprando {
        width:80%;
        margin-left:10%;
    }
}